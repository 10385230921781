@import url("//fonts.googleapis.com/css?family=Muli:200,400,400i,700,700i,900");
html, body {
  color: #636466;
  margin: 0;
  padding: 0;
  font-family: Muli, sans-serif;
  font-size: 100%;
}

a {
  position: relative;
  color: #6ebe45;
  text-decoration: none;
  font-weight: 200;
}

a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -.1rem;
  left: 0rem;
  background-color: #6ebe45;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

a:hover {
  color: #6ebe45;
  -webkit-transition: all 0.5s ease-in-out 0s;
  text-decoration: none;
}

a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

a.portfolio-item {
  display: block;
  margin-top: 2rem;
  padding-bottom: 1rem;
}

h1 {
  margin: .5em;
  font-family: Muli, sans-serif;
  font-size: 3em;
  font-weight: 200;
  text-align: center;
}

h1.soon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  margin: 0 auto;
}

h1.home-page {
  top: 50%;
  height: 0;
  padding-top: 17%;
}

h2 {
  margin: .75em 0 .25em;
  font-family: Muli, sans-serif;
  color: #c7c8ca;
}

h3 {
  margin: .75em 0 .5em;
  font-family: Muli, sans-serif;
  line-height: 1.5;
}

p, li {
  font-size: 1.15em;
  line-height: 1.5;
}

nav ul {
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border;
  box-sizing: border;
  margin: 0;
  padding: 0 6%;
  list-style: none;
  text-align: center;
  background: #fff;
}

nav ul li {
  display: inline;
  padding: 0 .75em;
  font-size: 1.5em;
  letter-spacing: .2em;
}

nav ul li a:before {
  left: -.1em;
}

.navbar-toggler-icon {
  color: #6ebe45;
}

footer {
  clear: both;
  margin: 4em auto 2em;
  color: #c7c8ca;
  font-size: .75em;
  text-align: center;
}

footer ul {
  margin: 0;
  padding: 0;
  text-align: center;
}

footer li {
  list-style: none;
  display: inline-block;
  margin: .5rem;
  color: #636466;
  font-size: .8rem;
}

footer li a {
  left: .1em;
  display: block;
}

footer li i, footer li svg {
  margin: 0 auto;
  color: #6ebe45;
  font-size: 1.5rem;
}

footer .eyes {
  color: #6ebe45;
  font-weight: 900;
}

footer .mouth {
  color: #636466;
}

footer.home-page {
  bottom: -1rem;
}

.resume, .about {
  width: 80%;
  max-width: 56em;
  margin: 0 auto 2em;
  text-align: left;
}

.home-page {
  position: absolute;
  margin: 0 auto;
  left: 50%;
  width: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.page-nav {
  margin: 0;
  padding: 0;
  text-align: center;
}

.page-nav li {
  display: inline;
  list-style: none;
  margin: .225rem;
}

.page-nav a:before {
  left: 0;
}

.scroll-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  opacity: 0;
  cursor: pointer;
  padding: .5rem 1rem 1rem;
  background: #6ebe45;
  color: #fff;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  transition: all 0.5s ease-in-out 0s;
}

.scroll-top i {
  display: inline;
  font-size: 2rem;
}

#fullStack, #webDesign, #graphicDesign {
  margin-top: 5rem;
}

.portfolio-item h3 {
  margin: 0;
}

.portfolio-item .subhead {
  color: #c7c8ca;
  font-weight: bold;
}

.portfolio-item p {
  color: #636466;
}

.portfolio-item ul {
  margin: 0;
  padding: 0;
  text-align: center;
}

.portfolio-item li {
  list-style: none;
  display: inline-block;
  margin: .5rem;
  color: #636466;
  font-size: .8rem;
}

.portfolio-item li i, .portfolio-item li svg {
  margin: 0 auto;
  color: #6ebe45;
  font-size: 1.5rem;
}

.portfolio-item img {
  display: block;
  margin: 1rem auto 0;
}

.contact-information {
  margin: .5em 0 0;
  font-size: 1.15em;
  line-height: 1.5em;
  text-align: center;
}

.resume p {
  margin: .25em 0 .75em;
}

.skills {
  -webkit-columns: 3;
  -moz-columns: 3;
  columns: 3;
  list-style-type: none;
  margin: 0 0 0 2em;
  padding: 0;
  font-weight: bold;
  text-align: left;
}

.skills li {
  margin: 0 0 .35em;
}

.experience, .education, .extras, .portfolio ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.experience h3 {
  margin: .25em 0 .5em;
  color: #6ebe45;
  font-size: 1.4rem;
}

.experience ul {
  list-style-type: none;
  margin: 0 0 1.5em;
}

.experience ul li {
  margin: .5em 0;
}

.experience li li {
  font-size: 1em;
}

.experience ul li::before {
  content: "\2022";
  position: relative;
  top: 0em;
  float: left;
  margin: 0 0 0 -1em;
  width: 1em;
  font-size: 1em;
  color: #c7c8ca;
}

.company {
  color: #636466;
  font-weight: bold;
}

.location {
  color: #636466;
}

.dates {
  color: #c7c8ca;
  font-weight: normal;
}

.degree, .gpa {
  font-weight: bold;
}

ul.files {
  margin: 0;
  padding: 0;
  text-align: center;
}

ul.files li {
  list-style: none;
  display: inline-block;
  margin: 2rem 1.5rem 0;
  color: #636466;
  font-size: 1em;
}

ul.files li a {
  left: .1em;
  display: block;
}

ul.files li i, ul.files li svg {
  margin: 0 auto;
  color: #6ebe45;
  font-size: 2rem;
}

.headshot {
  display: block;
  height: 300px;
  margin: 50px auto 0;
  border-radius: 200px;
}

#logo, #logomark {
  position: absolute;
  top: 0;
  left: 0;
}

.gray {
  fill: #636466;
}

.light-gray {
  fill: #c7c8ca;
}

.green {
  fill: #6ebe45;
}

.logomark-container {
  display: block;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 6%;
  max-width: 4em;
}

a.logomark-container:before {
  height: 0 !important;
}

.logo-resume {
  position: relative;
  width: 80%;
  max-width: 10em;
  height: 2.5em;
  margin: 1em auto 0;
  text-align: center;
}

@media (min-width: 768px) {
  .about, .resume, .portfolio {
    text-align: justify;
  }
}

@media print {
  .resume {
    width: 100%;
    max-width: 62em;
  }
  .scroll-top {
    display: none;
  }
}
