
// global

@import url('//fonts.googleapis.com/css?family=Muli:200,400,400i,700,700i,900');


// Sass variables

$light:   #c7c8ca;
$dark:    #636466;
$action:  #6ebe45;
$font:    Muli, sans-serif;


html, body {
  color: $dark;
  margin: 0;
  padding: 0;
  font-family: $font;
  font-size: 100%;
}

a {
  position: relative;
  color: $action;
  text-decoration: none;
  font-weight: 200;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -.1rem;
    left: 0rem;
    background-color: $action;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
  }

  &:hover {
    color: $action;
    -webkit-transition: all 0.5s ease-in-out 0s;
    text-decoration: none;
  }

  &:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  &.portfolio-item {
    display: block;
    margin-top: 2rem;
    padding-bottom: 1rem;
  }

}

h1 {
  margin: .5em;
  font-family: $font;
  font-size: 3em;
  font-weight: 200;
  text-align: center;

  &.soon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);
    margin: 0 auto;
  }

  &.home-page {
    top: 50%;
    height: 0;
    padding-top: 17%;
  }
}

h2 {
  margin: .75em 0 .25em;
  font-family: $font;
  color: $light;
}

h3 {
  margin: .75em 0 .5em;
  font-family: $font;
  line-height: 1.5
}

p, li {
  font-size: 1.15em;
  line-height: 1.5;
}


// nav

nav ul {
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border;
  box-sizing: border;
  margin: 0;
  padding: 0 6%;
  list-style: none;
  text-align: center;
  background: #fff;

  li {
    display: inline;
    padding: 0 .75em;
    font-size: 1.5em;
    letter-spacing: .2em;

    a:before {
      left: -.1em;
    }
  }
}

.navbar-toggler-icon {
  color: $action;
}



// footer

footer {
  clear: both;
  margin: 4em auto 2em;
  color: $light;
  font-size: .75em;
  text-align: center;

  ul {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  li {
    list-style: none;
    display: inline-block;
    margin: .5rem;
    color: $dark;
    font-size: .8rem;

    a {
      left: .1em;
      display: block;
    }

    i, svg {
      margin: 0 auto;
      color: $action;
      font-size: 1.5rem;
    }
  }

  .eyes {
    color: $action;
    font-weight: 900;
  }

  .mouth {
    color: $dark;
  }

  &.home-page {
    bottom: -1rem;
  }
}


// PAGE SPECIFIC STYLES

%page {
	width: 80%;
	max-width: 56em;
	margin: 0 auto 2em;
	text-align: left;
}


.home-page {
  position: absolute;
  margin: 0 auto;
  left: 50%;
  width: 50%;
  -webkit-transform: translate3d(-50%,-50%,0);
  transform: translate3d(-50%,-50%,0);
}


// portfolio

.page-nav {
  margin: 0;
  padding: 0;
  text-align: center;

  li {
    display: inline;
    list-style: none;
    margin: .225rem;
  }

  a:before {
    left: 0;
  }
}

.scroll-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  opacity: 0;
  cursor: pointer;
  padding: .5rem 1rem 1rem;
  background: $action;
  color: #fff;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  transition: all 0.5s ease-in-out 0s;

  i {
    display: inline;
    font-size: 2rem;
  }

}

#fullStack, #webDesign, #graphicDesign {
  margin-top: 5rem;
}

.portfolio-item {

  h3 {
    margin: 0;
  }

  .subhead {
    color: $light;
    font-weight: bold;
  }

  p {
    color: $dark;
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  li {
    list-style: none;
    display: inline-block;
    margin: .5rem;
    color: $dark;
    font-size: .8rem;

    i, svg {
      margin: 0 auto;
      color: $action;
      font-size: 1.5rem;
    }
  }

  img {
    display: block;
    margin: 1rem auto 0;
  }

}


// resume
.resume {
  @extend %page;
}

.contact-information {
  margin: .5em 0 0;
	font-size: 1.15em;
  line-height: 1.5em;
	text-align: center;
}

.resume p {
  margin: .25em 0 .75em;
}

.skills {
	-webkit-columns: 3;
  -moz-columns: 3;
  columns: 3;
  list-style-type: none;
  margin: 0 0 0 2em;
  padding: 0;
  font-weight: bold;
  text-align: left;
}

.skills li {
  margin: 0 0 .35em;
}

.experience, .education, .extras, .portfolio ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

}

.experience h3 {
  margin: .25em 0 .5em;
  color: $action;
  font-size: 1.4rem;
}

.experience ul {
  list-style-type: none;
  margin: 0 0 1.5em;
}

.experience ul li {
  margin: .5em 0;
}

.experience li li {
  font-size: 1em;
}

.experience ul li::before {
  content: "\2022";
  position: relative;
  top: 0em;
  float: left;
  margin: 0 0 0 -1em;
  width: 1em;
  font-size: 1em;
  color: $light;
}

.company {
  color: $dark;
  font-weight: bold;
}

.location {
  color: $dark;
}

.dates {
  color: $light;
  font-weight: normal;
}

.degree, .gpa {
  font-weight: bold;
}

ul.files {
  margin: 0;
  padding: 0;
  text-align: center;

  li {
    list-style: none;
    display: inline-block;
    margin: 2rem 1.5rem 0;
    color: $dark;
    font-size: 1em;

    a {
      left: .1em;
      display: block;
    }

    i, svg {
      margin: 0 auto;
      color: $action;
      font-size: 2rem;
    }
  }
}


// about
.about {
  @extend %page;
}

.headshot {
  display: block;
  height: 300px;
  margin: 50px auto 0;
  border-radius: 200px;
}


// svg logo
#logo, #logomark {
  position: absolute;
  top: 0;
  left: 0;
}

.gray {
  fill: $dark;
}

.light-gray {
  fill: $light;
}

.green {
  fill: $action;
}

.logomark-container {
  display: block;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 6%;
  max-width: 4em;
}

a.logomark-container:before {
  height: 0 !important;
}

.logo-resume {
	position: relative;
  width: 80%;
  max-width: 10em;
  height: 2.5em;
	margin: 1em auto 0;
  text-align: center;
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .about, .resume, .portfolio {
      text-align: justify;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

@media print { 
  .resume {
    width: 100%;
    max-width: 62em;
  }

  .scroll-top {
    display: none;
  }
 }
